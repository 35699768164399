import axios from "axios";

const instance = axios.create({
  // baseURL: "https://103.55.70.106:444/", //f e 

   baseURL: process.env.REACT_APP_API_URL, //f e 

  });

instance.interceptors.request.use(
  (config) => {
    const token = JSON.parse(localStorage.getItem("user"))?.Token;

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
      config.headers.CompanyId = window.localStorage.getItem("CompanyId");
    }
    return config;
  },
  (error) => Promise.reject(error)
);

export default instance;
